
<template>
  <div class="autocomplete" :class="{loading: isLoading, contact: type == 'contact' || type == 'filleul' || type == 'parrain' }">
    <input
      type="text"
      v-model="search.name"
      class="input"
      :placeholder="placeholder" 
      @keyup="onChange"
      @focus="results.length > 0 ? isOpen = true : isOpen = false"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      :class="{open: isOpen, contact: type == 'contact' || type == 'filleul' || type == 'parrain'}"
      id="autocomplete-input"
      :data-city="search.city"
      :data-zipcode="search.zipcode"
      :data-department="search.department"
      :autocomplete="autocompleteType"
    />
    <ul 
    v-show="isOpen"
    class="autocomplete-results" >
      <!--<li-->
      <!--  class="loading"-->
      <!--  v-if="isLoading">-->
      <!--  <img src="/img/loading.gif" />-->
      <!--</li>-->
      <li
        v-for="(result, i) in results"
        :key="result.id"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result.place_name }} 
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .autocomplete {
    position: relative;
    display: inline-block;
    width: 100%;
    
    &.contact {
      width: 100%;
    }
    &.loading:after {
      content: ' ';
      display: inline-block;
      height: 30px;
      width: 30px;
      background: url(/img/loading.gif) no-repeat;
      background-size: cover;
      position: absolute;
      top: calc(50% - 15px);
      right: 0;
      
    }
    @media(min-width: 992px) {
      width: 50%;
      
    }
    
    input {
      width: 100%!important;
      outline: none;
      font-size: 16px;
      font-weight: 800;
      &.open {
        border-radius: 25px 0 0 0!important;
      }
      &.contact {
        font-size: 14px;
        font-weight: 500;
        border-radius: 0 !important;
      }
     
    }
  }
  
  .autocomplete-results {
    padding: 0;
    margin: 0;
    width: calc(100%);
    height: auto;
    max-height: 200px;
    overflow: auto;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    
    li {
      color: #ee3780;
      font-size: 14px;
      font-weight: 800;
      padding: 0 0 0 20px;
      display: flex;
      align-items: center;
      min-height: 24px;
        img {
          display: inline-block;
          margin: 0 8px 3px 0;
          padding: 5px 0;
          
        }
      &.autocomplete-result {
        line-height: 24px;
        list-style: none;
        text-align: left;
        font-weight: 500;
        min-height: 20px;
        cursor: pointer;
        color: #000;
        padding-bottom: 0;
        //border-bottom: 1px solid #9b9b9b;
        
        &:hover, &.is-active {
          background-color: #f2f2f2;
          font-weight: 800;
        }
      }
      
    }
  }


</style>

<script>

import axios from "axios";

  export default {
    //name: 'autocomplete',
    props: {
      isAsync: {
        type: Boolean,
        required: false,
        default: false,
      },
      oldValue: {
        type: String,
        required: false,
        default: ''
      },
      regionScope: {
        type: String,
        required: false,
        default: ''
      },
      citiesScope:{
        type: String,
        required: false,
        default: ''
      },
      items: {
        type: Array,
        required: false,
        default: () => [],
      },
      placeholder: {
        type: String,
        required: false,
        default: 'Ville ou nom de département'
      },
      type: {
        type: String,
        required: false,
        default: 'search'
      }
    },
    data() {
      return {
        search: {
          name: this.oldValue
        },
        result: {},
        autocompleteType: this.type == "contact" || this.type == "filleul" ? 'new-password' : 'off',
        results: [],
        cities: [],
        dpts: [],
        isLoading: false,
        isOpen: false,
        arrowCounter: -1,
        timeout: null
      };
    },
    methods: {
      onChange() {
        // Init a timeout variable to be used below
        clearTimeout(this.timeout);
        
        let self = this;
        // Make a new timeout set to go off in 1000ms (1 second)
        if(this.search.name != ' ') {
          if(this.search.name != '') {
            this.timeout = setTimeout(self.searchApi, 500);
          }
          
        }
        
      },
      searchApi() {
        var self = this;
        
        this.isLoading = true;
        
        var url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + this.search.name + '.json?access_token=' + 'pk.eyJ1Ijoic3RheXVwIiwiYSI6ImNraDNodmtzMTBleDYzMGxzbWxveGRqcHAifQ.rBPQN-V5TIg82rq3dA6ceA&country=fr&types=address';
        
        axios.get(url)
          .then(function(response) {
              console.log(response)
            self.results = response.data.features;

            self.isLoading = false;
            //self.filterResults();
            
            if(self.search.name != undefined && self.results.length == 0) {
              /* document.querySelectorAll('input[name="ville"]').forEach(function(input) {
                input.value = self.search.name;
              });
              document.querySelectorAll('input[name="country"]').forEach(function(input) {
                input.value = '';
              }); */
            }
            else {
              self.isOpen = true;
            }
          });
        
      },
      onArrowDown() {
        if (this.arrowCounter < this.results.length) {
          this.arrowCounter = this.arrowCounter + 1;
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
        }
      },
      onEnter() {
        this.search = this.results[this.arrowCounter];
        this.isOpen = false;
        this.arrowCounter = -1;
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          this.isOpen = false;
          this.arrowCounter = -1;
          // this.cities = {};
          // this.dpts = {};
        }
      },
      setResult(result) {
        
        this.search.name = result.place_name;
        this.result = {};
        
        this.result = {
            place_name: result.place_name,
            coordinates: result.geometry.coordinates
        }

        //this.search.name = result.name;
        this.isOpen = false;

        this.$emit('location_updated', this.result);
        
      },
      // setValues() {
      //   console.log(this.result)
      // },
      filterResults() {
        //this.results = this.items.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
        this.cities = this.results.filter(item => item.type == 'ville');
        this.dpts = this.results.filter(item => item.type == 'departement');
      },
    },
    mounted() {
      //this.$el.addEventListener('submit', this.setValues());
      document.addEventListener('click', this.handleClickOutside);
      console.log('autocomplete mounted')
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
//   http://emerige-pp.omycron.com/api/city?search=bouche&city_only=true
// http://emerige-pp.omycron.com/api/city?search=bouche
// http://emerige-pp.omycron.com/api/city?search=marseille&region=nord,sud





</script>
