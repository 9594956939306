<template>
  <div class="container" id="app">
    <div class="block"></div>
    <div class="is-flex is-align-items-center">
    <img src="/favicon.png" style="height: 90px;" />
    <img src="/logo-ph-horizontal.jpg" style="height: 50px; margin-left: 35px;"/>

    </div>

    <FormComponent msg="Welcome to Your Vue.js App"/>

    <div class="block">
    </div>
      <div class="subtitle has-text-centered">Ce simulateur vous est proposé grâce à la technologie PriceHubble</div>
  </div>
</template>

<script>
import FormComponent from './components/FormComponent.vue';


export default {
  name: 'App',
  components: {
    FormComponent
  }
}
</script>

<style lang="scss">

#app {

}
</style>
