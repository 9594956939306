<template>
 <div class="hello" id="simu">
  <div class="form-card">
   <div class="form-card-header">
    <div class="block">
     <button
      type="button"
      class="button font-b switch-step"
      id="prev"
      v-if="activeStep > 1"
      @click="prev"
     >
      Etape précédente
     </button>
    </div>
    <div class="block">
     <div class="timeline">
      <div
       class="step-indicator"
       v-for="(step, index) in steps"
       :key="index"
       :style="{ width: index == steps - 1 ? '24px' : '100%' }"
      >
       <div class="bubble">
        <span class="" :class="{ undone: step > activeStep }"></span>
       </div>
       <div
        class="line"
        :class="{ undone: step > activeStep }"
        v-show="index != steps - 1"
       ></div>
      </div>
     </div>
    </div>
   </div>
   <div class="form-card-body">
    <div
     id="steps"
     :active-step="activeStep - 1"
     :style="{ '--steps': +steps, '--active-step': activeStep - 1 }"
    >
     <div
      class="step"
      :class="{ 'step-visible': activeStep == 1 }"
      data-step="1"
     >
      <span class="title is-block has-text-centered font-b">
       Complétez le formulaire pour découvrir
       <strong>l’estimation de votre bien</strong>
      </span>

      <button
       type="button"
       class=" button font-b switch-step"
       id="next"
       v-if="activeStep < steps"
       :disabled="false"
       @click="next"
      >
       Etape suivante
      </button>
     </div>

     <div
      class="step"
      :class="{ 'step-visible': activeStep == 2 }"
      data-step="2"
     >
      <form id="form-step-2">
       <span class="title is-block has-text-centered font-b">
        Sélectionnez le type de votre bien
       </span>
       <div class="type-group">
        <button
         type="button"
         class="button switch-type"
         :class="{ selected: values.type == 'appartement' }"
         @click="values.type = 'appartement'"
        >
         <AppartPict />
         <span>Appartement</span>
        </button>
        <button
         type="button"
         class="button switch-type"
         :class="{ selected: values.type == 'maison' }"
         @click="values.type = 'maison'"
        >
         <MaisonPict />
         <span>Maison</span>
        </button>
       </div>
       <button
        type="button"
        class=" button font-b switch-step"
        id="next"
        v-if="activeStep < steps"
        :disabled="values.type == ''"
        @click="next"
       >
        Etape suivante
       </button>
      </form>
     </div>
     <div
      class="step"
      :class="{ 'step-visible': activeStep == 3 }"
      data-step="3"
     >
      <ValidationObserver v-slot="{ handleSubmit }">
       <form
        action=""
        id="form-step-3"
        @submit.prevent="handleSubmit(onSubmit)"
       >
        <span class="title is-block has-text-centered	 font-b">
         Caractéristiques de votre bien
        </span>
        <ValidationProvider rules="" vid="type" v-slot="{ x }">
         <input
          type="text"
          v-show="false"
          class="input"
          :class="x"
          name="type"
          v-model="values.type"
          placeholder="Ex : 2"
         />
        </ValidationProvider>
        <div class="columns">
         <div class="column is-half " style="">
          <div class="field">
           <label for="etage" class="label is-flex is-align-items-center">
            <EtagePict />
            <span class="ml-1">
             Étage
            </span>
           </label>
           <div class="control">
            <ValidationProvider
             rules="positive|required_if:type,appartement"
             v-slot="{ classes, errors }"
            >
             <input
              type="number"
              class="input"
              :class="classes"
              v-show="values.type == 'appartement'"
              name="etage"
              v-model.number="values.etage"
              placeholder="Ex : 2"
              value=""
             />
             <span class="help" :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
           </div>
          </div>
         </div>
         <div
          class="column is-half "
          v-show="values.type == 'appartement'"
          style=""
         >
          <div class="field">
           <label for="etage" class="label is-flex is-align-items-center">
            <ImmeublePict />
            <span class="ml-1">
             Nombre d’étages du bâtiment
            </span>
           </label>
           <div class="control">
            <ValidationProvider
             rules="positive|required_if:type,appartement"
             v-slot="{ classes, errors }"
            >
             <input
              type="number"
              class="input"
              :class="classes"
              name="nb_etages"
              v-model.number="values.nb_etages"
              placeholder="Ex : 2"
              value=""
             />
             <span class="help" :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
           </div>
          </div>
         </div>
        </div>
        <div class="columns">
         <div class="column is-half has-error" style="">
          <div class="field">
           <label for="surface" class="label is-flex is-align-items-center">
            <SurfacePict />
            <span class="ml-1">
             Surface loi Carrez (en m<sup>2</sup>)
            </span>
           </label>
           <div class="control">
            <ValidationProvider
             rules="positive|required"
             v-slot="{ classes, errors }"
            >
             <input
              type="number"
              class="input"
              :class="classes"
              name="surface"
              v-model.number="values.surface"
              placeholder="Ex : 2"
              value=""
             />
             <span class="help" :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
           </div>
          </div>
         </div>

         <div
          class="column is-half"
          v-show="values.type == 'appartement'"
          style=""
         >
          <div class="field">
           <label
            for="surface_balcon"
            class="label is-flex is-align-items-center"
           >
            <SurfaceBalconPict />
            <span class="ml-1">
             Surface du balcon (en m<sup>2</sup>)
            </span>
           </label>
           <div class="control">
            <ValidationProvider
             rules="positive|required_if:type,appartement"
             v-slot="{ classes, errors }"
            >
             <input
              type="number"
              class="input"
              :class="classes"
              name="surface_balcon"
              v-model.number="values.surface_balcon"
              placeholder="Ex : 2"
             />
             <span class="help" :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
           </div>
          </div>
         </div>
         <div class="column is-half" v-show="values.type == 'maison'" style="">
          <div class="field">
           <label
            for="surface_terrain"
            class="label is-flex is-align-items-center"
           >
            <SurfaceTerrainPict />
            <span class="ml-1">
             Surface du terrain (en m<sup>2</sup>)
            </span>
           </label>
           <div class="control">
            <ValidationProvider
             rules="positive|required_if:type,maison"
             v-slot="{ classes, errors }"
            >
             <input
              type="number"
              class="input"
              :class="classes"
              name="surface_terrain"
              v-model.number="values.surface_terrain"
              placeholder="Ex : 2"
              value=""
             />
             <span class="help" :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
           </div>
          </div>
         </div>
        </div>
        <div class="columns">
         <div class="column is-half" style="">
          <div class="field">
           <label for="annee" class="label is-flex is-align-items-center">
            <AnneePict />
            <span class="ml-1">
             Année de construction
            </span>
           </label>
           <div class="control">
            <ValidationProvider
             :rules="{
              positive: true,
              required: true,
              between: [1850, new Date().getFullYear()],
             }"
             v-slot="{ classes, errors }"
            >
             <input
              type="number"
              class="input"
              :class="classes"
              name="annee"
              v-model.number="values.annee"
              placeholder="Ex : 2"
             />
             <span class="help" :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
           </div>
          </div>
         </div>
         <div class="column is-half" style="">
          <div class="field">
           <label for="nb_pieces" class="label is-flex is-align-items-center">
            <NbPiecesPict />
            <span class="ml-1">
             Nombre de pièces
            </span>
           </label>
           <div class="control">
            <ValidationProvider
             rules="positive|required"
             v-slot="{ classes, errors }"
            >
             <input
              type="number"
              class="input"
              :class="classes"
              name="nb_pieces"
              v-model.number="values.nb_pieces"
              placeholder="Ex : 2"
              value=""
             />
             <span class="help" :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
           </div>
          </div>
         </div>
        </div>
        <button type="submit" class="button switch-step">Suivant</button>
       </form>
      </ValidationObserver>
     </div>

     <div
      class="step"
      :class="{ 'step-visible': activeStep == 4 }"
      data-step="4"
     >
      <form id="form-step-4">
       <span class="title is-block has-text-centered font-b">
        Etat de votre bien
       </span>
       <div class="type-group">
        <button
         type="button"
         class="button switch-type"
         :class="{ selected: values.etat == 'renovation_needed' }"
         @click="values.etat = 'renovation_needed'"
        >
         <ARenoverPict />
         <span>A rénover</span>
        </button>

        <button
         type="button"
         class="button switch-type"
         :class="{ selected: values.etat == 'well_maintained' }"
         @click="values.etat = 'well_maintained'"
        >
         <BonEtatPict />
         <span>Bon état</span>
        </button>

        <button
         type="button"
         class="button switch-type"
         :class="{ selected: values.etat == 'new_or_recently_renovated' }"
         @click="values.etat = 'new_or_recently_renovated'"
        >
         <RenovePict />
         <span>Rénové</span>
        </button>
       </div>
       <button
        type="button"
        class=" button font-b switch-step"
        id="next"
        v-if="activeStep < steps"
        :disabled="values.etat == ''"
        @click="next"
       >
        Etape suivante
       </button>
      </form>
     </div>

     <div
      class="step"
      :class="{ 'step-visible': activeStep == 5 }"
      data-step="5"
     >
      <form id="form-step-5">
       <div class="columns">
        <div class="column is-full">
         <span class="title is-block has-text-centered font-b">
          Avez-vous un garage ?
         </span>
         <div class="type-group">
          <button
           type="button"
           class="button switch-type"
           :class="{ selected: values.garage == 'oui' }"
           @click="values.garage = 'oui'"
          >
           <span>Oui</span>
          </button>
          <button
           type="button"
           class="button switch-type"
           :class="{ selected: values.garage == 'non' }"
           @click="values.garage = 'non'"
          >
           <span>Non</span>
          </button>
         </div>
        </div>
       </div>
       <button
        type="button"
        class=" button font-b switch-step"
        id="next"
        v-if="activeStep < steps"
        :disabled="values.garage == ''"
        @click="next"
       >
        Etape suivante
       </button>
      </form>
     </div>

     <div
      class="step"
      :class="{ 'step-visible': activeStep == 6 }"
      data-step="6"
     >
      <form id="form-step-6">
       <div class="columns">
        <div class="column is-full">
         <span class="title is-block has-text-centered font-b">
          Avez-vous un parking ?
         </span>
         <div class="type-group">
          <button
           type="button"
           class="button switch-type"
           :class="{ selected: values.parking == 'oui' }"
           @click="values.parking = 'oui'"
          >
           <span>Oui</span>
          </button>
          <button
           type="button"
           class="button switch-type"
           :class="{ selected: values.parking == 'non' }"
           @click="values.parking = 'non'"
          >
           <span>Non</span>
          </button>
         </div>
        </div>
       </div>
       <button
        type="button"
        class=" button font-b switch-step"
        id="next"
        v-if="activeStep < steps"
        :disabled="values.parking == ''"
        @click="next"
       >
        Etape suivante
       </button>
      </form>
     </div>

     <div
      class="step"
      :class="{ 'step-visible': activeStep == 7 }"
      data-step="7"
     >
      <div class="column is-full" style="">
       <div class="field">
        <label for="address" class="label is-flex is-align-items-center">
         <span class="ml-1">
          Adresse du bien
         </span>
        </label>
        <div class="control">
         <geo-coder v-on:location_updated="updateMap" />
        </div>
       </div>
      </div>
      <div class="column is-full">
       <MglMap
        :accessToken="accessToken"
        :mapStyle="mapStyle"
        :center.sync="center"
        :zoom="4"
        @load="onMapLoaded"
       >
        <MglMarker :coordinates="coordinates" color="blue" />
       </MglMap>
      </div>
      <button
       type="button"
       class=" button font-b switch-step"
       id="next"
       v-if="activeStep < steps"
       @click="next"
      >
       Etape suivante
      </button>
     </div>

     <div
      class="step"
      :class="{ 'step-visible': activeStep == 8 }"
      data-step="8"
     >
      <div class="columns">
       <div class="column is-full" style="">
        <span class="title is-block has-text-centered font-b"
         >Notre estimation de valeur pour votre bien</span
        >
       </div>
      </div>
      
      <div class="columns is-flex is-flex-direction-row is-justify-content-center">
       <div class="column is-narrow ">
        <div
         class="result is-flex is-flex-direction-column is-justify-content-center is-align-items-center "
        >
         <div class="value-pict has-text-centered">
          <SaleLowerPict />
         </div>
         <span class="value"
          >{{ currencyFormat(valuations.sale.salePriceRange.lower) }}
         </span>
         <span class="value-title">Valeur basse</span>
        </div>
       </div>
       <div class="column is-narrow">
        <div
         class="result is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
        >
         <div class="value-pict has-text-centered">
          <SaleMiddlePict />
         </div>
         <span class="value">{{ currencyFormat(valuations.sale.salePrice) }}  </span>
         <span class="value-title">Valeur conseillée</span>
        </div>
       </div>
       <div class="column is-narrow">
        <div class="result is-flex is-flex-direction-column is-justify-content-center is-align-items-center ">
          <div class="value-pict has-text-centered">
          <SaleUpperPict />
         </div>
         <span class="value">{{ currencyFormat(valuations.sale.salePriceRange.upper) }}</span>
         <span class="value-title">Valeur haute</span>
        </div>
       </div>
      </div>

      <div class="block"></div>
      <div class="columns is-flex is-flex-direction-row is-justify-content-center">
       <div class="column is-full" style="">
        <span class="title is-block has-text-centered font-b"
         >Notre estimation de loyer pour votre bien</span
        >
       </div>
      </div>
      <div class="columns is-flex is-flex-direction-row is-justify-content-center">
       <div class="column is-narrow">
        <div class="result is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <div class="value-pict has-text-centered">
          <RentLowerPict />
         </div>
         <span class="value">{{ currencyFormat(valuations.rent.rentNetRange.lower) }} </span>
         <span class="value-title">Loyer bas</span>
        </div>
       </div>
       <div class="column is-narrow">
        <div class="result is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <div class="value-pict has-text-centered">
          <RentMiddlePict />
         </div>
         <span class="value">{{ currencyFormat(valuations.rent.rentNet) }} </span>
         <span class="value-title">Loyer conseillé</span>
        </div>
       </div>
       <div class="column is-narrow">
        <div class="result is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <div class="value-pict has-text-centered">
          <RentUpperPict />
         </div>
         <span class="value">{{ currencyFormat(valuations.rent.rentNetRange.upper) }} </span>
         <span class="value-title">Loyer haut</span>
        </div>
       </div>
      </div>

      <div class="block">
        <div class="columns">
          <div class="column is-full">
            <a
              href="mailto:mainstream@3cent60.net"
              class=" button font-b switch-step"
              id="cta"
                           
            >
              Confiez-nous votre bien
            </a>
          </div>
        </div>
      </div>
     </div>
    </div>
   </div>
   <!-- <div class="form-card-footer">
    <button
     type="button"for
     class="button font-b switch-step"
     id="prev"
     v-if="activeStep > 1"
     @click="prev"
    >
     Etape précedente
    </button>
    
   </div> -->
  </div>
 </div>
</template>

<script>
import axios from "axios";
import AppartPict from "@/assets/img/pict-appartement.svg";
import MaisonPict from "@/assets/img/pict-maison.svg";
import EtagePict from "@/assets/img/pict-etage.svg";
import ImmeublePict from "@/assets/img/pict-immeuble.svg";
import SurfacePict from "@/assets/img/pict-surface.svg";
import SurfaceBalconPict from "@/assets/img/pict-surface-balcon.svg";
import SurfaceTerrainPict from "@/assets/img/pict-surface-terrain.svg";
import AnneePict from "@/assets/img/pict-annee.svg";
import NbPiecesPict from "@/assets/img/pict-nb-pieces.svg";
import ARenoverPict from "@/assets/img/pict-a-renover.svg";
import BonEtatPict from "@/assets/img/pict-bon-etat.svg";
import RenovePict from "@/assets/img/pict-renove.svg";
import SaleLowerPict from "@/assets/img/pict-sale-lower.svg";
import SaleMiddlePict from "@/assets/img/pict-sale-middle.svg";
import SaleUpperPict from "@/assets/img/pict-sale-upper.svg";
import RentLowerPict from "@/assets/img/pict-rent-lower.svg";
import RentMiddlePict from "@/assets/img/pict-rent-middle.svg";
import RentUpperPict from "@/assets/img/pict-rent-upper.svg";

import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { between } from "vee-validate/dist/rules";
import { required_if } from "vee-validate/dist/rules";
import { configure } from "vee-validate";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "vue-mapbox";
import GeoCoder from "./GeoCoder.vue";

configure({
 classes: {
  valid: "is-success",
  invalid: "is-danger",
  dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
  // ...
 },
});

extend("required_if", {
 ...required_if,
 message: "Ce champ est obligatoire",
});

extend("required", {
 validate(value) {
  return {
   required: true,
   valid: ["", null, undefined].indexOf(value) === -1,
  };
 },
 message: "Ce champ est obligatoire",
 computesRequired: true,
});

extend("positive", {
 validate: (value) => {
  return value >= 0;
 },
 message: "Doit être positif",
});

extend("between", {
 ...between,
 message: "Doit être entre 1850 et " + new Date().getFullYear(),
});

export default {
 name: "FormComponent",
 components: {
  AppartPict,
  MaisonPict,
  EtagePict,
  ImmeublePict,
  SurfacePict,
  SurfaceBalconPict,
  SurfaceTerrainPict,
  AnneePict,
  NbPiecesPict,
  ARenoverPict,
  BonEtatPict,
  RenovePict,
  SaleLowerPict,
  SaleMiddlePict,
  SaleUpperPict,
  RentLowerPict,
  RentMiddlePict,
  RentUpperPict,
  ValidationProvider,
  ValidationObserver,
  MglMap,
  MglMarker,
  GeoCoder,
 },
 props: {
  msg: String,
 },
 data: function() {
  return {
   access_token: "",
   accessToken:
    "pk.eyJ1Ijoic3RheXVwIiwiYSI6ImNraDNodmtzMTBleDYzMGxzbWxveGRqcHAifQ.rBPQN-V5TIg82rq3dA6ceA",
   mapStyle: "mapbox://styles/mapbox/streets-v11",
   coordinates: [48.51276779174805, -2.7580156326293945],
   //steps: 3,
   steps: 8,
   activeStep: 1,
   center: [-2.209666999999996, 46.232192999999995],
   values: {
    type: "",
    etage: null,
    nb_etages: null,
    surface: null,
    surface_balcon: null,
    surface_terrain: null,
    annee: null,
    nb_pieces: null,
    etat: "",
    garage: "",
    parking: "",
    coordinates: [-2.7580156326293945, 48.51276779174805],
   },
   valuations: {
    sale: {
     confidence: "poor",
     coordinates: {
      latitude: 48.51276779174805,
      longitude: -2.7580156326293945,
     },
     currency: "EUR",
     salePrice: 145600,
     salePriceRange: { lower: 122300, upper: 168900 },
     value: 145600,
     valueRange: { lower: 122300, upper: 168900 },
    },
    rent: {
     confidence: "good",
     coordinates: {
      latitude: 48.51276779174805,
      longitude: -2.7580156326293945,
     },
     currency: "EUR",
     rentGross: 616,
     rentGrossRange: { lower: 570, upper: 662 },
     rentNet: 604,
     rentNetRange: { lower: 559, upper: 649 },
     value: 616,
     valueRange: { lower: 570, upper: 662 },
    },
   },
  };
 },
 computed: {},
 methods: {
  getToken() {
   //let
   let app = this;

   axios
    .post("https://api.pricehubble.com/auth/login/credentials", {
     username: process.env.VUE_APP_PRICE_HUBBLE_USERNAME,
     password: process.env.VUE_APP_PRICE_HUBBLE_PASSWORD,
    })
    .then(function(response) {
     app.access_token = response.data.access_token;
    })
    .catch(function(error) {
     console.log(error);
    });
  },
  prev() {
   console.log("next");
   this.activeStep--;
  },
  next() {
   console.log("next");
   this.activeStep++;
   if (this.activeStep == this.steps) {
    this.makeValuations();
   }
  },
  async updateMap(event) {
   this.coordinates = event.coordinates;
   (this.values.coordinates = event.coordinates),
    await this.asyncActions.flyTo({
     center: event.coordinates,
     zoom: 15,
     speed: 1,
    });
  },
  onSubmit() {
   console.log("submit !");

   if (this.activeStep == 3) {
    this.next();
   }
  },
  makeValuations() {
   var self = this;

   let sale = {
    dealType: "sale",
    valuationInputs: [
     {
      property: {
       location: {
        coordinates: {
         latitude: this.values.coordinates[1],
         longitude: this.values.coordinates[0],
        },
       },
       propertyType: {
        code: this.values.type == "appartement" ? "apartment" : "house",
       },
       buildingYear: this.values.annee,
       livingArea: this.values.surface,
       numberOfRooms: this.values.nb_pieces,
       numberOfIndoorParkingSpaces: this.values.pagaragerking == "oui" ? 1 : 0,
       numberOfOutdoorParkingSpaces: this.values.parking == "oui" ? 1 : 0,
       condition: {
        bathrooms: this.values.etat,
        kitchen: this.values.etat,
        flooring: this.values.etat,
        windows: this.values.etat,
        masonry: this.values.etat,
       },
      },
     },
    ],
    countryCode: "FR",
   };

   let rent = {
    dealType: "rent",
    valuationInputs: [
     {
      property: {
       location: {
        coordinates: {
         latitude: this.values.coordinates[1],
         longitude: this.values.coordinates[0],
        },
       },
       propertyType: {
        code: this.values.type == "appartement" ? "apartment" : "house",
       },
       buildingYear: this.values.annee,
       livingArea: this.values.surface,
       numberOfRooms: this.values.nb_pieces,
       numberOfIndoorParkingSpaces: this.values.pagaragerking == "oui" ? 1 : 0,
       numberOfOutdoorParkingSpaces: this.values.parking == "oui" ? 1 : 0,
       condition: {
        bathrooms: this.values.etat,
        kitchen: this.values.etat,
        flooring: this.values.etat,
        windows: this.values.etat,
        masonry: this.values.etat,
       },
      },
     },
    ],
    countryCode: "FR",
   };

   if (this.values.type == "maison") {
    sale.valuationInputs[0].property.landArea = this.values.surface_terrain;
    rent.valuationInputs[0].property.landArea = this.values.surface_terrain;
   } else {
    sale.valuationInputs[0].property.balconyArea = this.values.surface_balcon;
    rent.valuationInputs[0].property.balconyArea = this.values.surface_balcon;
    sale.valuationInputs[0].property.floorNumber = this.values.etage;
    rent.valuationInputs[0].property.floorNumber = this.values.etage;
    sale.valuationInputs[0].property.numberOfFloorsInBuilding = this.values.nb_etages;
    rent.valuationInputs[0].property.numberOfFloorsInBuilding = this.values.nb_etages;
   }

   axios
    .post(
     "https://api.pricehubble.com/api/v1/valuation/property_value?access_token=" +
      this.access_token,
     sale
    )
    .then(function(response) {
     console.log(response.data.valuations[0][0]);
     self.valuations.sale = response.data.valuations[0][0];
    })
    .catch(function(error) {
     console.log(error);
    });

   axios
    .post(
     "https://api.pricehubble.com/api/v1/valuation/property_value?access_token=" +
      this.access_token,
     rent
    )
    .then(function(response) {
     self.valuations.rent = response.data.valuations[0][0];
    })
    .catch(function(error) {
     console.log(error);
    });
  },
  currencyFormat: function(price) {
   return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
   }).format(price);
  },
  async onMapLoaded(event) {
   this.asyncActions = event.component.actions;
  },
 },
 created() {
  this.mapbox = Mapbox;
  // We need to set mapbox-gl library here in order to use it in template
 },
 mounted: function() {
  this.getToken();
 },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
